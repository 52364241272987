import '../styles/ProfilePage.sass'

import React from 'react'
import Select from 'react-select'
import ProfileHeader from '../components/ProfileHeader'
import { useNavigate } from 'react-router-dom'
import { sexOptions, ageOptions, heightOptions, weightOptions, skipBreakfastOptions } from '../resources/constants'
import { useAppDispatch, useAppSelector } from '../store/hook'
import { setAge, setHeight, setSex, setWeight, setSkipBreakfast } from '../store/reducers/profile'
import { useState } from 'react'
import { mealLevels } from '../resources/constants'

function ProfilePage() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const profile = useAppSelector(state => state.profile)
    const [showInfo, setShowInfo] = useState(false)
    
    return (
        <div id='profile-page' className='main-container'>
            <ProfileHeader />

            {!showInfo && (
                <>
                    <div className='profile-selectors'>
                        <Select
                            options={sexOptions}
                            value={sexOptions.find((sex) => sex.value === profile.sex)}
                            onChange={ (newSex) => dispatch(setSex(newSex?.value === 'male' ? 'male' : 'female')) }
                            placeholder='je suis...'
                            isSearchable={false}
                            styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderRadius: 20
                            }),
                            }}
                            />

                        <Select
                            options={ageOptions}
                            value={ageOptions.find((age) => age.value === profile.age)}
                            onChange={ (newAge) => newAge && dispatch(setAge(newAge.value)) }
                            placeholder='mon âge'
                            isSearchable={false}
                            styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderRadius: 20
                            }),
                            }}
                            />

                        <Select
                            options={heightOptions}
                            value={heightOptions.find((height) => height.value === profile.height)}
                            onChange={ (newHeight) =>  newHeight && dispatch(setHeight(newHeight.value)) }
                            placeholder='ma taille'
                            isSearchable={false}
                            styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderRadius: 20
                            }),
                            }}
                            />

                        <Select
                            options={weightOptions}
                            value={weightOptions.find((weight) => weight.value === profile.weight)}
                            onChange={ (newWeight) => newWeight && dispatch(setWeight(newWeight.value)) }
                            placeholder='mon poids actuel'
                            isSearchable={false}
                            styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderRadius: 20
                            }),
                            }}
                            />

                        <Select
                            options={skipBreakfastOptions}
                            value={skipBreakfastOptions.find((skipBreakfast) => skipBreakfast.value === profile.skipBreakfast)}
                            onChange={ (newSkipBreakfast) => newSkipBreakfast && dispatch(setSkipBreakfast(newSkipBreakfast.value)) }
                            placeholder='petit déjeuner'
                            isSearchable={false}
                            styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderRadius: 20
                            }),
                            }}
                            />

                        <input 
                            id='exit-button'
                            type='button' 
                            value='OK' 
                            onClick={() => navigate(-1)} 
                        />

                    </div>

                    <div className='how-it-works' onClick={() => setShowInfo(!showInfo)} >
                        <img className='information-icon' alt='icône information' src='/assets/myfoodlife_icon_information.svg' />
                        <span>envie de savoir comment ça marche&nbsp;?</span>
                    </div>
                </>

                )}


                {showInfo && (
                    <div className='extended-information'>
                        <div>
                            <img className='myfoodlife-compose-logo' src='/assets/myfoodlife_compose_logo.png' alt='myfoodlife compose'/>
                        </div>
                        <div>
                            estime votre <b>dépense énergétique quotidienne</b> à partir de 4&nbsp;caractéristiques :
                        </div>

                        <br/>
                        <div>
                            <b>sexe</b>&nbsp;&nbsp;|&nbsp;&nbsp;<b>âge</b>&nbsp;&nbsp;|&nbsp;&nbsp;<b>taille</b>&nbsp;&nbsp;|&nbsp;&nbsp;<b>poids</b>
                        </div>
                        <br/>

                        <div>
                            en utilisant la formule dite de <i>Black et al.</i> qui fait référence au plan mondial.
                        </div>
                        <div>
                            <img className='arrow-icon' alt='flèche' src='/assets/arrow_down_icon.svg' />
                        </div>
                        <div>Ensuite, selon que vous avez l&apos;habitude de prendre un petit déjeuner ou non, <b>ce besoin en énergie est réparti</b> sur vos 3 ou 2 repas quotidiens.
                        </div>
                        <div>
                            <img className='arrow-icon' alt='flèche' src='/assets/arrow_down_icon.svg' />
                        </div>
                        <div>
                             Ceci permet d&apos;estimer le nombre de calories dont vous avez besoin pour un <b>déjeuner normal</b> et de vous proposer plusieurs options&nbsp;:
                        </div>
                        <br/>
                        <div className='meal-level-buttons'>
                        { (Object.keys(mealLevels) as (keyof typeof mealLevels)[]).map((level) => (
                            <div
                                className={'meal-level-button'} 
                                
                                key={level}
                            >
                                {mealLevels[level].label}
                                { level !== 'normal' && level !== 'at_will' ?
                                    <div className='energy-increment'>
                                        {mealLevels[level].coeff < 1 ? '-' : '+'}{(Math.abs(mealLevels[level].coeff - 1))*100}%
                                    </div>
                                : ''}
                            </div>
                        ))}
                        </div>
                        <br/>
                        <br/>
                        <div>
                             pour composer chaque jour votre déjeuner
                             en fonction de vos envies, de votre niveau d&apos;activité physique ou sportive et de vos objectifs personnels.
                        </div>
                        <br/>

                        <div id='exit-button' onClick={() => setShowInfo(!showInfo)} >
                            j&apos;ai compris
                        </div>
                    </div>

                )}

        </div>
    )
}

export default ProfilePage