import React, { useState, useEffect } from 'react'
import VideoModel from '../model/VideoModel'
import Markdown from 'react-markdown'
import '../styles/Video.sass'

interface VideoProps {
  video: VideoModel
  collapseSpotlight: () => void
}

function Video( {video, collapseSpotlight}: VideoProps ) {

  return (
    <>
      <div id='spotlight-expanded-container'>
        <div id='close-button' onClick={() => collapseSpotlight()}>
          <img className='close-icon' alt='icône croix' src='/assets/close_icon.svg' />
        </div>
        <div className='video'>
          <iframe width="560" height="315" src={video.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
          </iframe>
        </div>
      </div>
    </>

  )
}

export default Video
