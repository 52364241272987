import '../styles/Menu.sass'

import React from 'react'
import Section from './Section'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import MealLevelPicker from './MealLevelPicker'
import { IMenu } from '@myfoodlifesas/db'

function Menu({ menu }: { menu: IMenu }) {
    const navigate = useNavigate()
    
    return (
        <div id='menu'>
            <div className='meal-level-and-profile-toggle'>
                <MealLevelPicker />

                <div className='display-profile-toggle' onClick={() => { navigate(ROUTES.PROFILE_ROUTE) }}>
                    <img className='user-icon' alt='icône utilisateur' src='/assets/user_icon.svg' />
                    <span>personnaliser</span>
                </div>
            </div>

            <div id='menu-sections-list'>
                <div className='menu-header'>
                    <div className='menu-title'>
                        Je compose&nbsp;:
                    </div>
                    <div className='menu-subtitle'>
                        dans le menu du {new Date(menu.date).formated()}
                    </div>

                </div>

                {menu.sections.map((section) => (
                    
                    <Section key={section.name} section={section} menu={menu} features={menu.restaurant.features} context='compose'/>
                    
                ))}
            </div>

        </div>   
    )
}

export default Menu