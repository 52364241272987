import React, { useState, useEffect } from 'react'
import PollModel from '../model/PollModel'
import '../styles/Poll.sass'

interface PollProps {
  poll: PollModel
  collapseSpotlight: () => void
}

function Poll( {poll, collapseSpotlight}: PollProps ) {
  const [stage, setStage] = useState<'intro' | 'question' | 'thankYou' | 'end' >('intro')
  const [answer, setAnswer] = useState('')

  useEffect(() => {
    if (stage === 'intro') {
      const timer = setTimeout(() => {
        setStage('question')
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [stage])

  useEffect(() => {
    if (stage === 'thankYou') {
      const timer = setTimeout(() => {
        setStage('end')
      }, 1500)

      return () => clearTimeout(timer)
    }
  }, [stage])

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(event.target.value)
  }

  const handleAnswerSubmit = () => {
    setStage('thankYou')
  }

  return (
    <div id='spotlight-expanded-container'>
      <div id='close-button' onClick={() => collapseSpotlight()}>
        <img className='close-icon' alt='icône croix' src='/assets/close_icon.svg' />
      </div>

      { (stage === 'intro' || stage === 'question' || stage === 'thankYou' || stage === 'end') &&
        <div className='poll-message left'>
          { "Bonjour ! Aujourd’hui, une question rapide sur " + poll.about + " :" }
        </div>
      }
      { (stage === 'question' || stage === 'thankYou' || stage === 'end') && (
        <div className='poll-message right'>
          <div className='question-title'>
              { poll.question_title }
          </div>
          <div className='choices-list'>
            {
              poll.choices_list.map((choice, index) =>
                <div className='choice' key={"choice_" + index.toString()} onClick={handleAnswerSubmit}>
                  {  choice }
                </div>
              )
            }
          </div>
        </div>
        )
      }
 
      { (stage === 'thankYou' || stage === 'end') && 
        <div className='poll-message left'>
        Merci pour ce retour. Bonne journée.
        </div>
      }

      { stage === 'end' &&
        <div onClick={() => collapseSpotlight()}>
          <div className='poll-message right'>
            <div className='choices-list'>
              <div className='choice'>
                TERMINER
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Poll
