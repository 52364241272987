import { IMenu } from '@myfoodlifesas/db'
import React from 'react'

function RestaurantHeader({ menu, isSpotlightDisplayed }: { menu: IMenu, isSpotlightDisplayed: boolean }) {
  
    return (
        <div className='restaurant-header' style={{ backgroundImage:`url('${menu.restaurant.coverUrl}')` }} >
            <img className={`restaurant-logo ${isSpotlightDisplayed ? "spotlight-active" : ""}`} alt='logo du restaurant' src={menu.restaurant.logoUrl || '/assets/empty_restaurant_logo_url.png'} />
        </div>
        )
}

export default RestaurantHeader