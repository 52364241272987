import { createSlice } from '@reduxjs/toolkit'
import ProfileModel from '../../model/ProfileModel'

const initialState: ProfileModel = {
    sex: 'female',
    age: 45,
    height: 1.67,
    weight: 64,
    skipBreakfast: false,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setSex(state, action: { payload: ProfileModel['sex'] }) {
            state.sex = action.payload
        },
        setAge(state, action: { payload: ProfileModel['age'] }) {
            state.age = action.payload
        },
        setHeight(state, action: { payload: ProfileModel['height'] }) {
            state.height = action.payload
        },
        setWeight(state, action: { payload: ProfileModel['weight'] }) {
            state.weight = action.payload
        },
        setSkipBreakfast(state, action: { payload: ProfileModel['skipBreakfast'] }) {
            state.skipBreakfast = action.payload
        },
    },
})
export const { setSex, setAge, setHeight, setWeight, setSkipBreakfast } = profileSlice.actions

export default profileSlice.reducer
